import { render, staticRenderFns } from "./projekt.vue?vue&type=template&id=65493700&scoped=true&"
import script from "./projekt.vue?vue&type=script&lang=js&"
export * from "./projekt.vue?vue&type=script&lang=js&"
import style0 from "./projekt.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./projekt.vue?vue&type=style&index=1&id=65493700&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65493700",
  null
  
)

export default component.exports