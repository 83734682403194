<template>
  <div class="mission" v-if="currentRoute">
    <stage :big="true" :image="currentRoute.acf.stageImage.url" :title="currentRoute.title.rendered"/>
    <introduction
      fontWeight="bold"
      :subline="getLang === 'de' ? 'Das Projekt' : 'The project'"
      :maintext="currentRoute.acf.mission"
    />
    <div class="mission__block">
        <div class="mission__block-text" v-html="currentRoute.acf.description.text"/>
        <div class="mission__block-quote">
            <blockquote>
                {{currentRoute.acf.description.zitat}}
            </blockquote>
        </div>
    </div>
    <div class="mission__content">
      <div class="mission__grid" v-if="currentRoute.acf.mission_blocks">
        <div
          v-for="(obj, i) in currentRoute.acf.mission_blocks"
          :key="i"
          class="mission__grid-row"
        >
          <div class="mission__grid-image-wrapper">
            <img
              :src="obj.image.url"
              :alt="obj.image.alt"
              class="mission__grid-image"
              :class="{'mission__grid-image--relative' : obj.imageOriented[0] === 'true'}"
            />
          </div>
          <div class="mission__grid-text-wrapper">
            <h2 v-if="i === 0">
              {{obj.title}}
            </h2>
            <h3 v-else>
              {{obj.title}}
            </h3>
            <p>
              {{obj.text}}
            </p>
          </div>
        </div>
      </div>
      <div class="mission__section-bg">
          <h3 class="mission__sectionhead">{{currentRoute.acf.more}}</h3>
          <inline-blocks :withMargin="false" :blocks="currentRoute.acf.blocks"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapGetters} from 'vuex'
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'

export default {
  name: 'Mission',
  components: {
    Stage,
    Introduction,
    InlineBlocks,
  },
  computed: {
    ...mapGetters([
        'getFetched',
        'getLang'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.name];
      return getFetched;
    },
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.path);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
  }
}
</script>
<style lang="scss">
.mission {
  &__block-text {
      max-width: 445px;
      h3 {
          margin: 0 0 .6rem;
          font-size: 24px;
          line-height: 1.2;
          font-family: 'neue-plak';
          font-weight: 500;  
      }
      a {
        color: #f3972b;
        font-style: italic;
        text-decoration: none;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
      }
  }
}
</style>
<style lang="scss" scoped>
.mission {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
  &__block {
      font-size: 18px;
      line-height: 1.5;
      display: flex;
      flex: 1;
      max-width: 1140px;
      margin: auto;
      color: #182952;
      padding: .8rem;
      min-width: 300px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
      @media (min-width: 1024px) {
          padding: 0 0 0 110px;
          margin-bottom: 150px;
      }
  }
  &__block-quote {
      max-width: 350px;
      blockquote {
          font-style: italic;
          font-size: 24px;
          font-weight: 700;
          margin: 0 0 1.6rem 0;
          @media (min-width: 1024px) {
            margin: 0;
        }
      }
  }
  &__grid {
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
      margin-bottom: 150px;
      display: grid;
      grid-auto-rows: 1fr;
    }
  }
  &__grid-row {
    max-width: 1140px;
    margin: 0;
    color: #182952;
    display: flex;
    flex-wrap: wrap;
    background-color: #f2f8ff;
    &:nth-child(2n + 2) {
      flex-direction: row-reverse;
      background-color: #ffffff;
    }
    & > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 320px;
      position: relative;
      @media (min-width: 1024px) {
        max-width: 50%;
      }
      h2 {
          margin: 0 0 .6rem;
          font-size: 32px;
          line-height: 1.2;
          font-family: 'neue-plak';
          font-weight: 500;  
      }
      h3 {
          margin: 0 0 .6rem;
          font-size: 24px;
          line-height: 1.2;
          font-family: 'neue-plak';
          font-weight: 500;  
      }
      p {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }
  }
  &__grid-text-wrapper {
    padding: 1.6rem;
    @media (min-width: 1024px) {
      padding: 60px;
    }
  }
  &__grid-image {
    width: 100%;
    height: auto;
    max-height: 320px;
    object-fit: cover;
    @media (min-width: 768px) {
      position: absolute;
      height: 100%;
      max-height: 100%;
    }
    &--relative {
      position: relative;
    }
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 1.6rem;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
  }
}
</style>
